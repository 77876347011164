<template>
    <section class="mx-15">
    <v-row no-gutters justify="center" class="mx-xl-15">
      <v-col class="d-flex align-center" cols="2" align="center"
      v-for="(item, index) in itemsRecognition"
              :key="index">
        <v-img
              :src="require(`../../../assets/newlanding/Recognition/${item.icon}.svg`)"
              alt="Reconocimientos"
              :max-width="136"
              class="img-carousel my-7"
            ></v-img>
      </v-col>
      </v-row>
    </section>
</template>

<script>
export default {
  name: 'RecognitionWeb',
  props: ['isMobile'],
  data: () => ({
    itemsCarousel: [],
    number: 0,
    imageSrc: '@/assets/newlanding/Recognition/Icon-recognition.png',
    isZoomed: false,
    window: {
      width: 1022,
      height: 700,
    },
    itemsRecognition: [
      {
        icon: 'present',

      },
      {
        icon: 'ranking',

      },
      {
        icon: 'diversity2023',
      },
      {
        icon: 'home2023',
      },
      {
        icon: 'peru',

      },
      {
        icon: 'woman',

      },
      {
        icon: 'diversity',

      },
      {
        icon: 'home',

      },
      {
        icon: 'millennials',

      },
      {
        icon: 'iso45001',

      },
      {
        icon: 'iso27001',

      },
      {
        icon: 'iso9001',

      },
      {
        icon: 'iso14001',

      },
    ],
  }),
  computed: {
    marginTopClass() {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1800) {
        return 'margin-top-large';
      }
      if (windowWidth >= 1024) {
        return 'margin-top-medium';
      }
      return '';
    },
    formatSize() {
      let dim = 'desktop';
      if (this.window.width <= 672) {
        dim = 'mob';
      } else if (this.window.width <= 1022) {
        dim = 'tablet';
      }
      return dim;
    },
    sizeImage() {
      let dimension = {
        width: 964,
        height: 757,
      };
      let proportion = dimension.height / dimension.width;
      let height = this.window.width * proportion * 0.5;
      let width = this.window.width * 0.5;
      if (this.formatSize === 'mob' || this.formatSize === 'tablet') {
        dimension = {
          width: 390,
          height: 250,
        };
        proportion = dimension.height / dimension.width;
        height = this.window.width * proportion;
        width = this.window.width;
      }
      return {
        width,
        height,
      };
    },
  },
  async created() {
    this.window = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    this.handleNextPrevCarousel(0);
  },
  methods: {
    zoomIn() {
      this.isZoomed = true;
    },
    zoomOut() {
      this.isZoomed = false;
    },
    handleNextPrevCarousel(num) {
      this.number = num + this.number;
      const array = this.itemsRecognition;
      this.itemsCarousel = array.slice(0 + this.number, 8 + this.number);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_app.scss";
.custom-carousel {
  height: 150px !important;
}
.img-carousel {
  width: 136px;
  transition: transform 0.3s;
}

.img-carousel:hover {
  transform: scale(1.2);
}

.btn-slide{
  display: flex;
width: 60px;
height: 60px;
justify-content: center;
align-items: center;
border-radius: 50%;
.v-icon {
  font-size: 48px !important;
}
}
</style>
