<!-- eslint-disable max-len -->
<template>
  <section>
    <div>
      <v-row no-gutters class="container-section-text">
        <v-col cols="12" md="6" lg="6" align="center">
            <v-img
              alt="quienes-somos-image"
              contain
              :src="require('../../../assets/newlanding/Recognition/quienes-somos.png')"
              :max-width="275"
              class="d-sm-none mt-4"
            />
            <v-img
              alt="quienes-somos-image"
              contain
              :src="require('../../../assets/newlanding/Recognition/quienes-somos.png')"
              :max-width="340"
              class="d-none d-sm-block d-md-none"
            />
            <v-img
              alt="quienes-somos-image"
              contain
              :src="require('../../../assets/newlanding/Recognition/quienes-somos.png')"
              :max-width="500"
              class="d-none d-md-block d-lg-none mb-0 img-md"
            />
            <v-img
              alt="quienes-somos-image"
              contain
              :src="require('../../../assets/newlanding/Recognition/quienes-somos.png')"
              :max-width="600"
              class="d-none d-lg-block mt-15 d-xl-none"
            />
            <v-img
              alt="quienes-somos-image"
              contain
              :src="require('../../../assets/newlanding/Recognition/quienes-somos.png')"
              :max-width="737"
              class="d-none d-xl-block mt-10"
            />
        </v-col>
        <v-col class="text-center text-md-end container-titles-section" cols="12" md="6" lg="6">
          <v-col>
            <p class="titleRecognitionMobile mx-auto">¿Quiénes somos?</p>
          </v-col>
          <v-col>
            <p class="contentRecognitionMobile mx-auto mb-2 mx-md-0"><span>Konectamos</span> empresas,</p>
            <p class="contentRecognitionMobile mx-auto mb-2"><span>Konectamos</span> personas,</p>
            <p class="contentRecognitionMobile mx-auto mb-2"><span>Konectamos</span> contigo.</p>
          </v-col>
          <v-col>
            <p class="descriptionRecognitionMobile mx-auto mt-xl-7 mt-lg-4">
              Combinamos talento, innovación y experiencia en la industria, ayudando a nuestros clientes en las
              relaciones con sus clientes, generando fidelidad y maximizando el valor, siempre con un firme compromiso
              con el medioambiente y la sociedad.
            </p>
          </v-col>
        </v-col>
      </v-row>
    </div>

    <div class="color-container">
      <div class="mt-8 mx-1 ml-6 mt-md-0 ml-md-15 pt-md-5 ">
        <p class="titleMedalsMobile">Reconocimientos</p>
      </div>
      <div class="align-container">
        <div class="hidden-md-and-up">
          <RecognitionMobile />
        </div>
        <div class="hidden-sm-and-down">
          <RecognitionWeb />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import RecognitionMobile from './RecognitionMobile.vue';
import RecognitionWeb from './RecognitionWeb.vue';

export default {
  name: 'RecognitionPage',
  components: {
    RecognitionWeb,
    RecognitionMobile,
  },
  computed: {
    marginTopClass() {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1800) {
        return 'margin-top-large';
      }
      if (windowWidth >= 1024) {
        return 'margin-top-medium';
      }
      return '';
    },
    formatSize() {
      let dim = 'desktop';
      if (this.window.width <= 672) {
        dim = 'mob';
      } else if (this.window.width <= 1022) {
        dim = 'tablet';
      }
      return dim;
    },
    sizeImage() {
      let dimension = {
        width: 964,
        height: 757,
      };
      let proportion = dimension.height / dimension.width;
      let height = this.window.width * proportion * 0.5;
      let width = this.window.width * 0.5;
      if (this.formatSize === 'mob' || this.formatSize === 'tablet') {
        dimension = {
          width: 390,
          height: 250,
        };
        proportion = dimension.height / dimension.width;
        height = this.window.width * proportion;
        width = this.window.width;
      }
      return {
        width,
        height,
      };
    },
  },
  async created() {
    this.window = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  },
  data: () => ({
    imageSrc: '@/assets/newlanding/Recognition/Icon-recognition.png',
    isZoomed: false,
    window: {
      width: 1022,
      height: 700,
    },
  }),
  methods: {
    zoomIn() {
      this.isZoomed = true;
    },
    zoomOut() {
      this.isZoomed = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_app.scss";

.img-md {
  margin-top: 30%;
}
.titleRecognitionMobile {
  font-size: 16px;
  font-weight: 500;
  font-family: $principal-font !important;
  color: $principal-green;
}
.contentRecognitionMobile {
  font-size: 26px;
  font-weight: 400;
  font-family: $principal-font !important;
  line-height: 0.9;
  color: $secondary-blue-light;
  span {
    font-family: $principal-font !important;
    font-weight: 600;
  }
}
.descriptionRecognitionMobile {
  font-size: 14px;
  font-weight: 400;
  font-family: $principal-font !important;
  line-height: 1.1;
  color: $secondary-black;
  margin-left: 18px !important;
  margin-right: 18px !important;
}
.titleMedalsMobile {
  font-size: 17px;
  font-weight: 900;
  font-family: $principal-font !important;
  color: $secondary-gray;
  margin-left: 15px;
}
.color-container {
  background-color: $secondary-white;
}

@media (min-width: 960px) {
  .titleMedalsMobile {
    font-size: 20px;
  }
  .titleRecognitionMobile {
    font-size: 24px;
    font-weight: 600;
  }
  .contentRecognitionMobile {
    font-size: 40px;
  }
  .container-titles-section {
    margin-top: 5%;
  }

  .descriptionRecognitionMobile {
    font-size: 20px;
    margin-right: 0px !important;
  }

  .container-section-text {
    padding: 0px 4.5%;
  }
}
@media (min-width: 1300px) {
  .descriptionRecognitionMobile {
    font-size: 20px;
    margin-right: 0px !important;
  }
  .contentRecognitionMobile {
    font-size: 50px;
  }
}

@media (min-width: 1920px) {
  .descriptionRecognitionMobile {
    font-size: 20px;
    padding-left: 10%;
  }
  .contentRecognitionMobile {
    font-size: 60px;
  }
  .container-titles-section {
    margin-top: 6%;
  }
}
</style>
