<template>
  <div>
    <v-container class="color-container" fluid>
      <v-container class="align-container">
        <v-row justify="space-around">
          <v-col
            cols="6"
            sm="4"
            md="3"
            lg="1"
            v-for="item in itemsRecognition"
            :key="item.img"
          >
            <v-card flat class="transparent">
              <v-card-text class="d-flex justify-center align-center">
                <v-img
                  :src="
                    require(`../../../assets/newlanding/Recognition/${item.icon}.svg`)
                  "
                  :style="{ 'max-width': item.maxWidth }"
                  alt="Reconocimientos"
                  class="v-image-zoom"
                  @mouseover="zoomIn"
                  @mouseout="zoomOut"
                ></v-img>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'RecognitionMobile',
  props: ['isMobile'],

  computed: {
    marginTopClass() {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1800) {
        return 'margin-top-large';
      }
      if (windowWidth >= 1024) {
        return 'margin-top-medium';
      }
      return '';
    },
    formatSize() {
      let dim = 'desktop';
      if (this.window.width <= 672) {
        dim = 'mob';
      } else if (this.window.width <= 1022) {
        dim = 'tablet';
      }
      return dim;
    },
    sizeImage() {
      let dimension = {
        width: 964,
        height: 757,
      };
      let proportion = dimension.height / dimension.width;
      let height = this.window.width * proportion * 0.5;
      let width = this.window.width * 0.5;
      if (this.formatSize === 'mob' || this.formatSize === 'tablet') {
        dimension = {
          width: 390,
          height: 250,
        };
        proportion = dimension.height / dimension.width;
        height = this.window.width * proportion;
        width = this.window.width;
      }
      return {
        width,
        height,
      };
    },
  },
  async created() {
    this.window = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  },
  data: () => ({
    imageSrc: '@/assets/newlanding/Recognition/Icon-recognition.png',
    isZoomed: false,
    window: {
      width: 1022,
      height: 700,
    },
    itemsRecognition: [
      {
        icon: 'present',
        maxWidth: '100px',
      },
      {
        icon: 'ranking',
        maxWidth: '150px',
      },
      {
        icon: 'diversity2023',
        maxWidth: '100px',
      },
      {
        icon: 'home2023',
        maxWidth: '100px',
      },
      {
        icon: 'peru',
        maxWidth: '100px',
      },
      {
        icon: 'woman',
        maxWidth: '100px',
      },
      {
        icon: 'diversity',
        maxWidth: '100px',
      },
      {
        icon: 'home',
        maxWidth: '100px',
      },
      {
        icon: 'millennials',
        maxWidth: '100px',
      },
      {
        icon: 'iso45001',
        maxWidth: '100px',
      },
      {
        icon: 'iso27001',
        maxWidth: '100px',
      },
      {
        icon: 'iso9001',
        maxWidth: '100px',
      },
      {
        icon: 'iso14001',
        maxWidth: '100px',
      },
    ],
  }),
  methods: {
    zoomIn() {
      this.isZoomed = true;
    },
    zoomOut() {
      this.isZoomed = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_app.scss";
.carousel-custom {
  max-width: 1000px;
  margin: 0 auto;
}

.carousel-item {
  width: calc(100% / 8);
}
.margin-top-large {
  margin-top: 56px;
}

.margin-top-medium {
  margin-top: 4px;
}
.titleRecognition {
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  font-family: $principal-font !important;
  color: $principal-greenAqua;
}
.titleRecognitionMobile {
  font-size: 16px;
  font-weight: 700;
  font-family: $principal-font !important;
  color: $principal-greenAqua;
}
.contentRecognition {
  font-size: 60px;
  font-weight: 700;
  font-family: $principal-font !important;
  line-height: 72px;
  color: $secondary-blue-light;
}
.contentRecognitionMobile {
  font-size: 36px;
  font-weight: 900;
  font-family: $principal-font !important;
  line-height: 0.9;
  color: $secondary-blue-light;
}
.descriptionRecognition {
  font-size: 20px;
  font-weight: 400;
  font-family: $principal-font !important;
  line-height: 1.1;
  color: $secondary-gray-dark;
}
.descriptionRecognitionMobile {
  font-size: 14px;
  font-weight: 400;
  font-family: $principal-font !important;
  line-height: 1.1;
  color: $secondary-gray-dark;
}

.titleMedals {
  font-size: 20px;
  font-weight: 700;
  font-family: $principal-font !important;
  color: $secondary-gray;
}
.titleMedalsMobile {
  font-size: 17px;
  font-weight: 900;
  font-family: $principal-font !important;
  color: $secondary-gray;
}

.color-container {
  background-color: $secondary-white;
}
.v-image-zoom {
  transition: transform 0.3s;
}
.v-image-zoom:hover {
  transform: scale(1.2);
}

@media (max-width: 1024px) {
  .titleRecognition {
    font-size: 16px;
    font-weight: 700;
    font-family: $principal-font !important;
    color: $principal-greenAqua;
  }
  .contentRecognition {
    font-size: 36px;
    font-weight: 700;
    font-family: $principal-font !important;
    line-height: 0.9;
    color: $secondary-blue-light;
  }
  .descriptionRecognition {
    font-size: 14px;
    font-weight: 400;
    font-family: $principal-font !important;
    line-height: 1.1;
    color: $secondary-gray-dark;
  }
}

@media (max-width: 1024px) {
  .titleRecognition {
    font-size: 16px;
    font-weight: 700;
    font-family: $principal-font !important;
    color: $principal-greenAqua;
  }
  .contentRecognition {
    font-size: 36px;
    font-weight: 700;
    font-family: $principal-font !important;
    line-height: 0.9;
    color: $secondary-blue-light;
  }
  .descriptionRecognition {
    font-size: 14px;
    font-weight: 400;
    font-family: $principal-font !important;
    line-height: 1.1;
    color: $secondary-gray-dark;
  }
}

@media (max-width: 330px) {
  .v-row > .v-col {
    padding-bottom: 2px;
    margin-bottom: 2px;
  }
}
</style>
